import { API } from 'aws-amplify';

export interface TestHistoryItem {
  id: number;
  assignmentId: string;
  endDate: string;
  score: number;
  maxScore: number;
  accuracy: number;
  title: string;
  username: string;
  name: string;
  grade: number;
  subject: string;
  ignore_test: number;
}

export interface TestHistoryResponse {
  result: TestHistoryItem[];
  total: number;
}

export interface TestHistoryFilters {
  page?: number;
  limit?: number;
  student?: string;
  subject?: string;
  title?: string;
  endDate?: string;
}

export async function getTestHistory(
  filters: TestHistoryFilters = {}
): Promise<TestHistoryResponse> {
  const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
  const path = 'api/testing/test-history';
  const queryParams = new URLSearchParams();

  Object.entries(filters).forEach(([key, value]) => {
    if (value) queryParams.append(key, value.toString());
  });

  const myInit = {
    headers: {},
    queryStringParameters: Object.fromEntries(queryParams),
  };

  return await API.get(apiName, path, myInit);
}
