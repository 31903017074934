import { API } from 'aws-amplify';

export async function rejectAutomatedTests(uniqueIds: number[]): Promise<void> {
  const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
  const path = 'api/testing/reject-automated-tests';

  await API.post(apiName, path, {
    headers: {},
    body: uniqueIds,
  });
}
