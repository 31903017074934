import { API } from 'aws-amplify';

type ManageTeamsAction = 'SELECT' | 'INSERT' | 'DELETE';

interface ManageTeamsInput {
  action: ManageTeamsAction;
  teamName?: string;
}

export async function manageTeams(
  input: ManageTeamsInput
): Promise<{ teams?: { team_name: string }[]; success?: boolean }> {
  const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
  const path = '/workflow/manage-teams';

  return await API.post(apiName, path, {
    headers: {},
    body: input,
  });
}
