import { API } from 'aws-amplify';

export interface CreateTestInventoryInput {
  title: string;
  grade: number;
  subject: string;
  legacy: boolean;
  assignable: boolean;
  language: string;
  levelupdown: boolean;
}

export async function createTestInventory(
  input: CreateTestInventoryInput
): Promise<{ result: boolean }> {
  const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
  const path = 'api/testing/test-inventory';
  const myInit = {
    body: input,
    headers: {},
  };
  return await API.post(apiName, path, myInit);
}
