import { API } from 'aws-amplify';

export interface AutomatedTestPending {
  unique_id: number;
  campus: string;
  id: string;
  level: string;
  reason: string;
  explanation: string;
  status: string;
  name: string;
  subject: string;
  test: string;
  testgrade: number;
  type: string;
  coachlink: string;
}

export async function getPendingAutomatedTests(): Promise<AutomatedTestPending[]> {
  const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
  const path = 'api/testing/pending-automated-tests';

  return await API.get(apiName, path, {
    headers: {},
  });
}
