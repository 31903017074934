import { API } from 'aws-amplify';

export async function assignWorkflow(body: {
  id: number;
  assigneeEmail?: string;
}): Promise<{ success: boolean }> {
  const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
  const path = '/workflow/assign-workflow';

  return await API.post(apiName, path, {
    headers: {},
    body,
  });
}
