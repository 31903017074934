import { API } from 'aws-amplify';

export interface UpdateWorkflowInput {
  id: number;
  status?: string;
  priority?: string;
  timeline?: string;
  assignee?: string | null;
}

export async function updateWorkflow(body: UpdateWorkflowInput): Promise<{ success: boolean }> {
  const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
  const path = 'workflow/update-workflow';

  return await API.put(apiName, path, {
    headers: {},
    body,
  });
}
