import { API } from 'aws-amplify';

export interface BulkUploadInput {
  subject: string;
  source: string;
  sheetUrl: string;
  sheetName: string;
}

export interface BulkUploadResponse {
  message?: string;
  permacodes?: string[];
  data?: any[];
  grades: string[];
  request?: BulkUploadInput;
}

export async function bulkUploadSkillPlan(input: BulkUploadInput): Promise<BulkUploadResponse> {
  try {
    const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
    const path = 'api/management/bulk-upload-skill-plan';
    const myInit = {
      body: input,
      headers: {},
    };
    const response = await API.post(apiName, path, myInit);
    return response;
  } catch (error: any) {
    // If it's a response with error data, throw it with the data
    if (error.response?.data) {
      throw {
        ...error.response.data,
        response: { data: error.response.data },
        status: error.response.status,
      };
    }

    // Otherwise handle other errors
    let errorMessage = 'Error uploading skills';
    if (error.message) {
      errorMessage = error.message;
    }
    throw new Error(errorMessage);
  }
}
