import { API } from 'aws-amplify';

export interface DistinctTestHistoryValues {
  students: string[];
  subjects: string[];
  titles: string[];
}

export async function getDistinctTestHistoryValues(): Promise<DistinctTestHistoryValues> {
  const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
  const path = 'api/testing/test-history-distinct';

  return await API.get(apiName, path, {
    headers: {},
  });
}
