import { API } from 'aws-amplify';

export interface AutoLoginTokenResponse {
  email: string;
  idToken: string;
  accessToken: string;
  refreshToken: string;
}

export const fetchAndSaveAutoLoginTokens = async (token: string): Promise<AutoLoginTokenResponse> => {
  const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
  const path = 'api/studyreel/get-auto-login-token';
  const response = await API.get(apiName, path, {
    queryStringParameters: {
      token,
    },
  });

  return response;
};
