import { API } from '@aws-amplify/api';
import { SkillFormData } from 'common/components/SkillModal';

export async function addSkill(data: SkillFormData): Promise<void> {
  try {
    const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
    const path = 'api/skill';
    const myInit = {
      body: data,
      headers: {},
    };

    const response = await API.post(apiName, path, myInit);
    if (!response) {
      throw new Error('Failed to add skill');
    }
    return response;
  } catch (error) {
    console.error('Error adding skill:', error);
    throw error;
  }
}
