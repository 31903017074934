import { API } from 'aws-amplify';

export interface ActiveAssignment {
  student_id: string;
  full_name: string;
  test_title: string;
  test_url: string;
  test_grade: number;
  created_on: Date;
  updated_on: Date;
  invalidated_on: Date | null;
  status: string;
  uuid: string;
  test_subject: string;
}

export async function getActiveAssignments(): Promise<ActiveAssignment[]> {
  const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
  const path = 'api/testing/active-assignments';

  return await API.get(apiName, path, {
    headers: {},
  });
}
