import { API } from 'aws-amplify';

export async function invalidateAssignment(uuid: string): Promise<{ success: boolean }> {
  const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
  const path = 'api/testing/active-assignments';

  return await API.put(apiName, path, {
    headers: {},
    body: { uuid },
  });
}
