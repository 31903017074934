import { API } from 'aws-amplify';

interface TestComputeResponse {
  statusCode?: number;
  message: string;
}

export async function triggerTestCompute(): Promise<TestComputeResponse> {
  const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
  const path = 'api/testing/trigger-test-compute';

  try {
    const response = await API.post(apiName, path, {});
    return response;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
}
