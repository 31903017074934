import { API } from '@aws-amplify/api';
import { SkillData } from 'common/@types/skill';

export async function getSkills(): Promise<SkillData[]> {
  try {
    const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
    const path = 'api/skill';
    const myInit = {
      headers: {},
      queryStringParameters: {},
    };
    const response = await API.get(apiName, path, myInit);
    if (!response?.result) {
      throw new Error('No skills found');
    }
    return response.result;
  } catch (error) {
    console.error('Error fetching skills:', error);
    throw error;
  }
}
