import { API } from 'aws-amplify';
import { WorkflowsResponse } from '../../@types/workflow';

export async function getClosedWorkflows(page: number, limit: number): Promise<WorkflowsResponse> {
  const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
  const path = `workflow/closed-workflows`;

  const queryParams = new URLSearchParams();
  queryParams.append('page', page.toString());
  queryParams.append('limit', limit.toString());

  const myInit = {
    headers: {},
    queryStringParameters: Object.fromEntries(queryParams),
  };

  return await API.get(apiName, path, myInit);
}
