import { API } from 'aws-amplify';

export async function deleteTestInventory(title: string): Promise<{ result: boolean }> {
  const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
  const path = 'api/testing/test-inventory';
  const myInit = {
    body: { title },
    headers: {},
  };
  return await API.del(apiName, path, myInit);
}
