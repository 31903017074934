import { API } from 'aws-amplify';

interface BulkUploadResponse {
  message: string;
  successCount: number;
  errors: Array<{ taskId: number; error: string }>;
}

export async function bulkUploadWorkflows(): Promise<BulkUploadResponse> {
  const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
  const path = '/workflow/bulk-upload';

  try {
    const response = await API.post(apiName, path, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    console.log('Amplify API response:', response); // Debug log
    return response;
  } catch (error) {
    console.error('Amplify API error:', error); // Debug log
    throw error;
  }
}
