import { API } from 'aws-amplify';

export interface UpdateTestHistoryInput {
  id: number;
  ignore_test: number;
}

export async function updateTestHistory(
  input: UpdateTestHistoryInput
): Promise<{ success: boolean }> {
  const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
  const path = 'api/testing/test-history';
  const myInit = {
    headers: {},
    body: {
      id: input.id,
      ignore_test: input.ignore_test,
    },
  };

  return await API.put(apiName, path, myInit);
}
