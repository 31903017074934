import { API } from 'aws-amplify';

interface TestAssignmentResponse {
  statusCode?: number;
  message: string;
}

export async function triggerTestAssignment(): Promise<TestAssignmentResponse> {
  const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
  const path = 'api/testing/trigger-test-assignment';

  try {
    const response = await API.post(apiName, path, {});
    return response;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
}
