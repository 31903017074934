import { API } from 'aws-amplify';

export interface ReorderTestInput {
  title: string;
  test_order: number;
}

export async function reorderTestInventory(
  tests: ReorderTestInput[]
): Promise<{ result: boolean }> {
  const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
  const path = 'api/testing/reorder-test-inventory';
  const myInit = {
    body: tests,
    headers: {},
  };
  return await API.post(apiName, path, myInit);
}
