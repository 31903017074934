import { API } from 'aws-amplify';

export interface TestInventoryItem {
  title: string;
  grade: number;
  subject: string;
  legacy: number;
  test_order: number;
  assignable: number;
  language: string;
  levelupdown: string;
}

export interface TestInventoryResponse {
  result: TestInventoryItem[];
}

export async function getTestInventory(input?: {
  counter?: number;
}): Promise<TestInventoryResponse> {
  const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
  const path = 'api/testing/test-inventory';
  const myInit = {
    headers: {},
  };
  return await API.get(apiName, path, myInit);
}
