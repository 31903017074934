import { API } from '@aws-amplify/api';
import {
  Block,
  MyRecommendationsOutput,
  Recommendation,
  SubBlock,
} from '@common/@types/recommendations';
export type SearchInput = {
  expectations: Record<string, Expectation>;
  startAt: number;
  pageSize: number;
};
type Expectation = {
  operator: string;
  value: unknown;
};
export async function searchRecommendations(input: SearchInput): Promise<MyRecommendationsOutput> {
  try {
    const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
    const path = `api/recommendations/search`;

    const myInit = {
      body: input,
    };
    const data: MyRecommendationsOutput = await API.post(apiName, path, myInit);

    const orderedSubjects = ['Math', 'Reading', 'Language', 'Science', 'Writing', 'Social Studies'];

    // Convert API data to a map for easy lookup
    const dataMap: Record<string, Block> = {};
    data.recommendations.forEach((block: Block) => {
      if (dataMap[block.subject]) {
        // If a block of this subject exists, append subBlocks
        dataMap[block.subject].subBlocks.push(...block.subBlocks);
      } else {
        dataMap[block.subject] = block;
      }
    });

    // Construct the final list of recommendations, filling in defaults where necessary
    const finalRecommendations: Block[] = orderedSubjects.map((subject) => {
      if (dataMap[subject]) {
        return dataMap[subject];
      } else {
        return {
          subject: subject,
          description: `In this learning block, you will learn ${subject} for 25 minutes. Do each task in order, stay focused, and avoid anti-patterns.`,
          subBlocks: [],
        };
      }
    });

    // Sorting the subBlocks
    finalRecommendations.forEach((block: Block) => {
      block.subBlocks.forEach((subBlock: SubBlock) => {
        subBlock.rows.sort((a: Recommendation, b: Recommendation) => a.priority - b.priority);
      });
    });

    return {
      recommendations_title: data.recommendations_title ?? '',
      recommendations: finalRecommendations,
    };
  } catch (error) {
    console.log('Error here', error.message);
    throw error;
  }
}
